export default function (Component) {
      Component.options.frontmatter = {
  "published": "2018-11-11T00:00:00.000Z",
  "tags": [
    "vue",
    "typescript",
    "dx"
  ],
  "title": "Type Vue without TypeScript",
  "excerpt": "<p>A practical guide for type checking vue components written in JS and getting things done.</p>\n"
}
    }